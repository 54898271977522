import { isNullOrUndefined } from '../../util/object';
import { joinBy } from './joinBy';

/**
 * @typedef {import('./computeDpy').Dpy} Dpy
 */

/**
 * Kpi meta
 * @typedef {Object} KpiMeta
 * @property {"high"|"low"} compare
 */

/**
 * Compare Metric
 * @typedef {Object} CompareMetric
 * @property {string} id
 * @property {number} value
 * @property {string} label
 * @property {string} formattedValue
 * @property {Dpy} dpy
 */

/**
 * Compare Metric Pair
 *
 * @typedef {Object} CompareMetricPair
 * @property {CompareMetric} metricA
 * @property {CompareMetric} metricB
 * @property {number} [comparison]
 *   Negative if `metricA is performing better, positive if `metricB` is
 *   performing better, or zero if both metrics are performing the same.
 * @property {number} [comparisonPy]
 *   Negative if `metricA is performing better relative to the previous year,
 *   positive if `metricB` is performing better, or zero if both metrics are
 *   performing the same.
 */

/**
 * Pairs to arrays of metrics into a single one. The result is a full outer join
 * of the two, by id.
 *
 * @param {CompareMetric[]} metricsA
 * @param {CompareMetric[]} metricsB
 * @param {Object<string, KpiMeta>} kpiMeta
 * @returns {CompareMetricPair[]}
 */
export const pairMetrics = (metricsA, metricsB) => {
  const joined = joinBy(metricsA, metricsB, 'metricA', 'metricB', m => m.id);

  return joined.map(({ metricA, metricB }) => {
    const defined = (metric, map) =>
      !isNullOrUndefined(metric) && !isNullOrUndefined(map(metric));
    const comparison = (raw, formatted) => (a, b) => {
      if (!defined(a, raw) || !defined(b, raw)) {
        return;
      }
      // The formatted values can be the same even when the raw values are
      // slightly different (e.g. 0.09% and 0.11% both displaying as 0.1%). We
      // want to treat them as equal when they are _displayed_ the same to avoid
      // a confusing UX.
      if (formatted(a) === formatted(b)) {
        return 0;
      }
      return (raw(b) - raw(a)) * (a.compare === 'low' ? -1 : 1);
    };

    return {
      id: metricA?.id ?? metricB?.id,
      label: metricA?.label ?? metricB?.label,
      metricA,
      metricB,
      comparison: comparison(
        m => m.value,
        m => m.formattedValue
      )(metricA, metricB),
      comparisonPy: comparison(
        m => m.dpy?.delta,
        m => m.dpy.formattedDelta
      )(metricA, metricB),
    };
  });
};
