import React from 'react';
import { Grid, List, ListItem, withStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Header from './Header';
import { Panel } from './Panel';
import ComparisonIcon from './ComparisonIcon';
import PerformancePill from './PerformancePill';
import KPiGraphTabs from './Cards/KPiGraphTabs';
import { Text2 } from '../typography';
import { transformTrends } from '../data/utils/transforms';

const metricPadding = 1;

const styles = theme => ({
  root: {
    padding: theme.spacing(0.75, 0, 0),
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid grey',
    justifyContent: 'space-between',
    padding: 0,
    '& > *': {
      width: '100%',
    },
    '& .recharts-responsive-container': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  panel: {
    backgroundColor: '#F5F5F5',
  },
  metric: {
    padding: theme.spacing(metricPadding, 0.25, metricPadding, 0.25),
    flex: 1,
  },
  comparisonIcon: {
    padding: theme.spacing(1, 0, metricPadding, 0),
    maxWidth: 24,
  },
  pyComparison: {},
  valueComparison: {
    height: 18,
    marginBottom: 4,
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  radio: {
    padding: 0,
  },
  label: {
    textAlign: 'center',
  },
  value: {
    margin: theme.spacing(0, 0, 0.5),
  },
  metricsGrid: {
    backgroundColor: '#F5F5F5',
  },
  bottomSpacer: {
    marginTop: '15px',
  },
  heading: {
    paddingTop: '20px',
  },
});

const Metric = ({ classes, metric, useLLY, ...props }) => {
  const { formattedValue, formattedLLYValue, dpy } = metric ?? {};

  return (
    <Grid
      className={classes.metric}
      item
      container
      direction="column"
      xs={props.xs}
      alignItems="center"
    >
      <Grid item className={classes.value}>
        <Text2 gray>
          {useLLY && formattedLLYValue
            ? formattedLLYValue || 'N/A'
            : formattedValue || 'N/A'}
        </Text2>
      </Grid>
      <Grid item>
        {dpy && (
          <PerformancePill delta={dpy} label={useLLY ? 'vs PPY' : 'vs PY'} />
        )}
      </Grid>
    </Grid>
  );
};

const ComparedMetricPair = ({ classes, metricPair, useLLY }) => {
  return (
    <Grid container className={classes.metricsGrid}>
      <Metric classes={classes} metric={metricPair.metricA} useLLY={useLLY} />
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        className={classes.comparisonIcon}
      >
        <ComparisonIcon
          comparison={metricPair.comparison}
          className={classes.valueComparison}
          style={{
            visibility:
              typeof metricPair.comparisonPy === 'number' ? 'hidden' : '',
          }}
        />
        <ComparisonIcon
          comparison={metricPair.comparisonPy}
          className={classes.pyComparison}
        />
      </Grid>
      <Metric classes={classes} metric={metricPair.metricB} useLLY={useLLY} />
    </Grid>
  );
};

// New MetricItem component for reusability
const MetricItem = ({ classes, item, useLLY, useUsdAmount }) => {
  console.log(item);
  const panelTitle = item?.label;
  const { formattedTrends: metricA } = item?.metricA ?? {};
  const { formattedTrends: metricB } = item?.metricB ?? {};
  const trendItems = transformTrends(metricA, metricB);
  const trendsLength = trendItems ? Object.keys(trendItems).length : 0;

  return (
    <ListItem key={item.id} className={classes.listItem}>
      <Panel
        collapsible={item.metricA?.hasTrends && trendsLength > 0}
        showTrendsLineIcon={item.metricA?.hasTrends && trendsLength > 0}
        title={panelTitle}
        classes={{ topSpacer: classes.panel }}
        showNoGap
      >
        {item.metricA.hasTrends && trendsLength > 0 && (
          <KPiGraphTabs
            data={trendItems}
            dots={true}
            dataKeyA="value"
            dataKeyB="value_cmp"
            strokeA="#35CAEE"
            strokeB="#8884d8"
            useLLY={useLLY}
            format={item.metricA?.format || item.metricB?.format}
            useUsdAmount={useUsdAmount}
          />
        )}
      </Panel>
      <ComparedMetricPair classes={classes} metricPair={item} useLLY={useLLY} />
    </ListItem>
  );
};
const CompareList = ({
  classes,
  items,
  revenueKPIsData,
  sfsRevenueData,
  bopisRevenueData,
  useLLY,
  useUsdAmount,
}) => {
  // Filter for promoted KPIs
  const promotedItems = items.filter(
    item => item.metricA?.promoted || item.metricB?.promoted
  );

  // Group metrics by category
  const groupedItems = items.reduce((acc, item) => {
    const category =
      item.metricA?.category || item.metricB?.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <>
      <div className={classes.heading}>
        <Header title="Highlight KPIS" />
      </div>
      <List className={classes.root}>
        {/* Highlight KPIs Section */}
        {promotedItems.length > 0 && (
          <div>
            {promotedItems.map(item => (
              <MetricItem
                key={item.id}
                classes={classes}
                item={item}
                useLLY={useLLY}
                useUsdAmount={useUsdAmount}
              />
            ))}
          </div>
        )}

        <div className={classes.heading}>
          <Header title="Revenue" />
        </div>
        <Panel title="Layer 1">
          {revenueKPIsData.length > 0 && (
            <div>
              {revenueKPIsData.map(item => (
                <MetricItem
                  key={item.id}
                  classes={classes}
                  item={item}
                  useLLY={useLLY}
                  useUsdAmount={useUsdAmount}
                />
              ))}
            </div>
          )}
        </Panel>
        <Panel
          title="Layer 2"
          collapsible
          customExpandIcons={[ExpandMoreIcon, ExpandLessIcon]}
        >
          {/* SFS Revenue Data */}
          {sfsRevenueData.length > 0 && (
            <div>
              {sfsRevenueData.map(item => (
                <MetricItem
                  key={item.id}
                  classes={classes}
                  item={item}
                  useLLY={useLLY}
                  useUsdAmount={useUsdAmount}
                />
              ))}
            </div>
          )}

          {/* BOPIS Revenue Data */}
          {bopisRevenueData.length > 0 && (
            <div>
              {bopisRevenueData.map(item => (
                <MetricItem
                  key={item.id}
                  classes={classes}
                  item={item}
                  useLLY={useLLY}
                  useUsdAmount={useUsdAmount}
                />
              ))}
            </div>
          )}
        </Panel>

        {/* Grouped KPIs Section */}
        <div className={classes.heading}>
          <Header title="Other KPIS" />
        </div>
        {Object.entries(groupedItems).map(([category, metrics], index) => (
          <div key={category}>
            <Panel
              title={category}
              collapsible
              initialCollapsed={index !== 0}
              customExpandIcons={[ExpandMoreIcon, ExpandLessIcon]}
              showNoGap
            >
              {metrics.map(item => (
                <MetricItem
                  key={item.id}
                  classes={classes}
                  item={item}
                  useLLY={useLLY}
                  useUsdAmount={useUsdAmount}
                />
              ))}
            </Panel>
          </div>
        ))}
      </List>
    </>
  );
};

const idValidator = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]).isRequired;
const metricValidator = PropTypes.shape({
  id: idValidator,
  label: PropTypes.string.isRequired,
  dpy: PropTypes.shape({
    delta: PropTypes.number,
    formattedDelta: PropTypes.string.isRequired,
    sentiment: PropTypes.oneOf(['good', 'bad', 'neutral']),
  }).isRequired,
});

CompareList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: idValidator,
      metricA: metricValidator,
      metricB: metricValidator,
      comparison: PropTypes.number,
      comparisonPy: PropTypes.number,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompareList);
